import React from 'react';
import Img from './prod4.png'

export default class Table extends React.Component {
  render() {
    
    return (
      <div>
        <img width="618" src={Img}/>
      </div>
    )
  }
}