import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import classnames from 'classnames'
import routers from '../../router'
import './index.scss'
function Header ({location: {pathname}}) {
  const headerData = routers.filter(t => t.order > 0).sort((a, b) => a.order - b.order)
  const Links = headerData.filter(t => t.order > 0).sort((a, b) => a.order - b.order).map((t, i) => {
    // if ('/index' === t.path) {
    //   return null
    // }
    let fil = pathname.split('/')[1]
    if(fil == 'productDetail')  {
      fil = 'product'
    }
    return <Link className={classnames({
      'acitve-nav': t.path.indexOf(fil) !== -1,
      'nav-item': true
    })} key={i} to={t.path}>{t.title}</Link>
  })
  const search = () => {
    let word = document.querySelector('#search').value
    if (word) {
      window.open(`https://www.google.com/search?q=${word}`)
    }
  }
  return <div className="header">
    <div className="inner">
      <div className="top-header">
        <div className="home">
          <Link  to='/home' className="header-logo-container">
            <img src={require('../../imgs/logo.png')} alt=""/>
            <p><img src={require('./name.png')} alt=""/></p>
          </Link>
        </div>
        <div className="right-header">
          <div className="search-container">
            <input type="text" id="search" placeholder="google カスタム検索"/>
            <span onClick={search}>
              <svg width="13" height="13" viewBox="0 0 13 13"><title>検索</title><path d="m4.8495 7.8226c0.82666 0 1.5262-0.29146 2.0985-0.87438 0.57232-0.58292 0.86378-1.2877 0.87438-2.1144 0.010599-0.82666-0.28086-1.5262-0.87438-2.0985-0.59352-0.57232-1.293-0.86378-2.0985-0.87438-0.8055-0.010599-1.5103 0.28086-2.1144 0.87438-0.60414 0.59352-0.8956 1.293-0.87438 2.0985 0.021197 0.8055 0.31266 1.5103 0.87438 2.1144 0.56172 0.60414 1.2665 0.8956 2.1144 0.87438zm4.4695 0.2115 3.681 3.6819-1.259 1.284-3.6817-3.7 0.0019784-0.69479-0.090043-0.098846c-0.87973 0.76087-1.92 1.1413-3.1207 1.1413-1.3553 0-2.5025-0.46363-3.4417-1.3909s-1.4088-2.0686-1.4088-3.4239c0-1.3553 0.4696-2.4966 1.4088-3.4239 0.9392-0.92727 2.0864-1.3969 3.4417-1.4088 1.3553-0.011889 2.4906 0.45771 3.406 1.4088 0.9154 0.95107 1.379 2.0924 1.3909 3.4239 0 1.2126-0.38043 2.2588-1.1413 3.1385l0.098834 0.090049z"></path></svg>
            </span>
          </div>
          <Link to="/contact" className="right-item lianxi">お問い合わせ</Link>
          
          {/* <a className="search" href="http://www.google.com" target="__blank">検 索</a> */}
          {/* <img width="32" style={{"margin": "0 10px"}} src={require('./icon_facebook.svg')} alt=""/> */}
          {/* <img width="32" src={require('./icon_twitter.svg')} alt=""/> */}
        </div>
      </div>
      <div className="nav">
        <Link to="/home" className={classnames({
          "header-home-container": true,
          "acitve-nav": true, //pathname.indexOf ('/index') > -1
        })}>
          <div className="header-home"><img src={require('./home-g.png')} /></div>
        </Link>
        {Links}
      </div>
    </div>
  </div>
}
export default withRouter(Header)