
// 产品list
export default {
  "1": {
    class: "ラジアル玉軸受",
    list: [{
      title: "深溝玉軸受",
      img: require('./imgdata/0001.jpg'),
      id: 11,
      info: [
        "あらゆる機械の回転部に使用。転がり軸受のなかで使用量がいちばん多い",
        "玉と軌道は点で接触。外輪と内輪の軌道溝の円弧の半径は、玉の半径よりわずかに大きい",
        "ラジアル荷重とアキシアル荷重、これらの合成荷重に耐える",
        "構造が簡単で、高い精度が得られ、高速回転にも適する",
      ]
    }, {
      id: 12,
      title: "単列アンギュラ玉軸受",
      img: require('./imgdata/0002.jpg'),
      info: [
        "玉と内輪・外輪の軌道がラジアル方向に対して接触角αで接し、ラジアル荷重と、一方向のアキシアル荷重に耐える",
        "接触角が大きいほど、アキシアル荷重の負荷能力が大きい。接触角が小さいほど、高速回転性が良くなる",
        "通常、軸受2個を対向させて用いる",
      ]
    }, {
      id: 13,
      title: "組合せアンギュラ玉軸受",
      img: require('./imgdata/0003.jpg'),
      info: [
        "単列アンギュラ玉軸受 2個以上を組み合わせて使用",
        "組み合わせは3通り<br/>背面組み合わせ(DB)、正面組み合わせ(DF)、並列組み合わせ(DT)",
        "すきま調整済みなので、組み合わせの変更は不可<br/>任意に組み換える場合は、フラッシュグラウンド軸受をご利用ください",
        "工作機械主軸用の軸受など高精度(JIS 5級以上)を要する場合は、予圧を与えて使用",
      ]
    }, {
      id: 14,
      title: "複列アンギュラ玉軸受",
      img: require('./imgdata/0004.jpg'),
      info: [
        "単列アンギュラ玉軸受を背面組合せにした形式（DB）で、内輪、外輪が一体",
        "単列アンギュラ玉軸受に比べて、一列に入る玉の数が少ないので、組合せアンギュラ玉軸受より負荷能力が小さい",
        "１個の軸受で、両方向のアキシアル荷重、ラジアル荷重、モーメント荷重に耐える",
      ]
    }, {
      id: 15,
      title: "自動調心玉軸受",
      img: require('./imgdata/0005.jpg'),
      info: [
        "外輪の軌道面が球面になっており、玉付き内輪を自由に傾けることができる",
        "取り付け誤差（軸心の不一致）や軸のたわみ補正に有利",
      ]
    }]
  },
  "2": {
    class: "スラスト玉軸受",
    list: [{
      id: 21,
      title: "単式スラスト玉軸受",
      img: require('./imgdata/0101.jpg'),
      info: [
        "アキシアル荷重のみを受けるように設計",
        "構成は、円柱状の平板に軌道みぞを設けた軌道盤２組、ボール、保持器",
        "両方向のスラスト荷重には、複式スラスト玉軸受を用意",
        "調心座付きは、取り付け誤差を補正可能",
      ]
    }, {
      id: 22,
      title: "複式スラスト玉軸受",
      img: require('./imgdata/0102.jpg'),
      info: [
        "両方向のアキシアル荷重を受けられる",
        "構成は、円柱状の平板に軌道溝を設けた軌道盤3組、ボール、保持器2組",
        "調心座付きは、取り付け誤差を補正可能",
      ]
    }, {
      id: 23,
      title: "複式スラストアンギュラ玉軸受",
      img: require('./imgdata/0103.jpg'),
      info: [
        "工作機械主軸でアキシアル荷重受け用に接触角を大きくした複式のスラスト軸受",
      ]
    }]
  },
  "3": {
    class: "スラストころ軸受",
    list: [{
      id: 31,
      title: "スラスト自動調心ころ軸受",
      img: require('./imgdata/0201.jpg'),
      info: [
        "ハウジング軌道盤の軌道は、軸線上に中心をもつ球面になっており、調心性あり<br /> 接触角αは約45°。アキシアル荷重と、ある程度のラジアル荷重に耐える",
        "射出成形機やクレーンなどの大型の機械に使用される",
      ]
    }]
  },
  "4": {
    class: "ベアリングユニット",
    list: [{
      id: 41,
      title: "ベアリングユニット",
      img: require('./imgdata/0301.jpg'),
      info: [
        "転がり軸受と軸受箱を組み合わせ、実用性と高い信頼性を持たせた",
        "ボールベアリングユニットの軸受の内部構造は、深みぞ軸受と同じ。定格荷重や信頼性、ほかの機能も同等",
      ]
    }]
  }
}